
import { defineAsyncComponent, defineComponent, computed, ref } from 'vue';
import sessionModule from '@/modules/auth/sessionModule';

export default defineComponent({
  inheritAttrs: false,
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    },
  },
  setup: () => {
    const components: any = {
      'personal-information': defineAsyncComponent(
        () => import('./PersonalInformation.vue'),
      ),
      'next-of-kin': defineAsyncComponent(
        () => import('./NextOfKinInformation.vue'),
      ),
    };

    const modal = ref<any>(null);
    const successModal = ref<any>(null);
    const personalInfo = ref<any>({});
    const defaultStep = ref<string>('personal-information');

    const { determineStep }: any = sessionModule();

    const step = computed(
      () => components[defaultStep.value ?? determineStep.value],
    );

    const navigateToNextOfKinInfo = (props: any): void => {
      personalInfo.value = {
        ...personalInfo.value,
        ...props,
      };
      defaultStep.value = 'next-of-kin';
    };

    const open = () => modal.value?.open();

    const back = () => (defaultStep.value = 'personal-information');

    const emitSuccess = () => {
      modal.value.close();
      successModal.value.open();
    };

    return {
      step,
      modal,
      open,
      navigateToNextOfKinInfo,
      personalInfo,
      successModal,
      emitSuccess,
      back,
    };
  },
});
